import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const _data = () => {
  const data = useStaticQuery(graphql`
    query {
      animationPhaseOne: file(relativePath: { eq: "animation1.png" }) {
        childImageSharp {
          fluid(maxWidth: 825) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      animationPhaseTwo: file(relativePath: { eq: "animation2.png" }) {
        childImageSharp {
          fluid(maxWidth: 825) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      animationPhaseThree: file(relativePath: { eq: "animation3.png" }) {
        childImageSharp {
          fluid(maxWidth: 825) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rachel: file(relativePath: { eq: "rachel.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quoteLeft: file(relativePath: { eq: "quoteLeft.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      quoteRight: file(relativePath: { eq: "quoteRight.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slackExample: file(relativePath: { eq: "slackExample.png" }) {
        childImageSharp {
          fluid(maxWidth: 622) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return data
}

export const AnimationImageOne = () => {
  return <Img fluid={_data().animationPhaseOne.childImageSharp.fluid} />
}

export const AnimationImageTwo = () => {
  return <Img fluid={_data().AnimationImageTwo.childImageSharp.fluid} />
}

export const AnimationImageThree = () => {
  return <Img fluid={_data().animationPhaseThree.childImageSharp.fluid} />
}

export const CustomerImage = () => {
  return (
    <Img
      fluid={_data().rachel.childImageSharp.fluid}
      style={{ maxHeight: "100%" }}
      imgStyle={{ objectPosition: "center", objectFit: "contain" }}
    />
  )
}

export const QuoteLeft = () => {
  return <Img fixed={_data().quoteLeft.childImageSharp.fixed} />
}

export const QuoteRight = () => {
  return <Img fixed={_data().quoteRight.childImageSharp.fixed} />
}

export const SlackExample = () => {
  return <Img fluid={_data().slackExample.childImageSharp.fluid} />
}
