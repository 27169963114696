import React from "react"
import styled from "styled-components"

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  border-left: ${props => (props.active ? "2px solid #6d99fd" : "none")};
  align-items: flex-start;
  padding-left: 16px;
`

const AnimationTitle = styled.h3`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  /* or 25px */
  text-align: center;
  color: #393e41;
  opacity: ${props => (props.active ? 1.0 : 0.4)};
`

const AnimationInfo = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-size: 0.75rem;
  /* or 25px */
  color: #999999;
  text-align: left;
  opacity: ${props => (props.active ? 1.0 : 0.4)};
`

const ProcessStep = ({ currentPhase, phase, title, body }) => {
  const isActive = currentPhase === phase
  return (
    <VStack active={isActive}>
      <AnimationTitle active={isActive}>{title}</AnimationTitle>
      <AnimationInfo active={isActive}>{body}</AnimationInfo>
    </VStack>
  )
}

export default ProcessStep
