import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate, Link } from "gatsby"

import Button from "../components/button"
import Layout from "../components/layout"
import {
  AnimationImageOne,
  CustomerImage,
  SlackExample,
} from "../components/images"
import ProcessStep from "../components/processStep"
import SEO from "../components/seo"
import vincitLogo from "../images/Vincit_logo_red_SPOT.svg"

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  top: 0;
  z-index: 100;
  padding: 24px;
  background: white;
`

const HStack = styled.div`
  display: flex;
  flex-direction: row;
`

const VStack = styled.div`
  display: flex;
  flex-direction: column;
`

const RefQuote = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(0.75em + 1vmin);
  line-height: 135%;
  color: #393e41;
`

const RefQuoteName = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.5em + 1vmin);
  color: #393e41;
`

const RefQuoteSign = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(0.5em + 1vmin);
  color: #393e41;
`

const SmileBellLogo = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.75em + 1vmin);
  line-height: 40px;
  color: #6d99fd;
`

const By = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: italic;
  font-size: 15px;
  line-height: 15px;
  color: #a3b2bb;
`

const Heading1 = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calc(2.2em + 0.5vmin);
  line-height: 117.5%;
  text-align: center;
  color: #393e41;
`

const Subheader = styled.h3`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* or 25px */
  text-align: center;
  color: #777777;
`

const FeatureTitle = styled.h1`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: calc(1.2em + 0.5vmin);
  line-height: 117.5%;
  /* or 45px */

  color: #393e41;
`

const FeatureNote = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.8em + 0.5vmin);
  line-height: 117.5%;
  /* or 45px */

  color: #393e41;
`

const FeatureDetails = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* or 152% */

  color: #999999;
`

const NavLink = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  /* or 152% */
  padding-left: 16px;
  line-height: 40px;
  color: #6d99fd;
`

const FeatureNoteDetails = styled.p`
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  /* or 152% */

  color: #999999;
`

const IndexPage = () => {
  const [currentPhase, setCurrentPhase] = useState(1)

  const setNextPhase = newPhase => {
    setCurrentPhase(newPhase)
    if (newPhase < 3) {
      setTimeout(() => {
        setNextPhase(newPhase + 1)
      }, 3000)
    } else {
      setTimeout(() => {
        setNextPhase(1)
      }, 3000)
    }
  }

  const [width, setWidth] = useState(400)

  const toContactPage = () => {
    navigate("/contact")
  }

  useEffect(() => {
    setTimeout(() => {
      setNextPhase(2)
    }, 3000)

    function handleResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const renderAnimationImage = current => {
    switch (current) {
      case 1:
        return <AnimationImageOne />
      case 2:
        return <AnimationImageOne />
      case 3:
        return <AnimationImageOne />
      default:
        return null
    }
  }

  const renderSlack = () => {
    if (width > 900) {
      return (
        <HStack
          name="integrations"
          style={{ minHeight: "70vh", justifyContent: "space-evenly" }}
        >
          <VStack
            style={{
              width: "33vw",
              justifyContent: "center",
            }}
          >
            <FeatureTitle>Integrates seamlessly with Slack</FeatureTitle>
            <FeatureDetails style={{ paddingTop: "1rem" }}>
              Upon visitors using SmileBell®, a message is sent directly to you
              indicating that your visitor as arrived.
            </FeatureDetails>
            <FeatureNote>Not using Slack?</FeatureNote>
            <FeatureNoteDetails style={{ paddingTop: "0.5rem" }}>
              No problem! Let's talk about custom integrations.
            </FeatureNoteDetails>
          </VStack>
          <VStack
            style={{
              alignSelf: "center",
              maxHeight: "100%",
              width: "40vw",
              height: "100%",
              justifyContent: "center",
              maxWidth: "450px",
            }}
          >
            <SlackExample />
          </VStack>
        </HStack>
      )
    } else {
      return (
        <VStack
          name="integrations"
          style={{
            minHeight: "70vh",
            justifyContent: "space-evenly",
            padding: "32px 0px 0px 0px",
          }}
        >
          <VStack
            style={{
              alignSelf: "center",
              maxHeight: "100%",
              width: "80vw",
              justifyContent: "center",
              maxWidth: "450px",
              padding: "32px 0px",
            }}
          >
            <SlackExample />
          </VStack>
          <VStack
            style={{
              justifyContent: "center",
              textAlign: "center",
              padding: "32px",
            }}
          >
            <FeatureTitle>Integrates seamlessly with Slack</FeatureTitle>
            <FeatureDetails style={{ paddingTop: "1rem" }}>
              When visitors smile at the SmileBell®, a message is sent directly
              to a person or a channel of your choosing, indicating that your
              visitor as arrived.
            </FeatureDetails>
            <FeatureNote>Not using Slack?</FeatureNote>
            <FeatureNoteDetails style={{ paddingTop: "0.5rem" }}>
              No problem! Let's talk about custom integrations.
            </FeatureNoteDetails>
          </VStack>
        </VStack>
      )
    }
  }

  const renderRef = () => {
    if (width > 700) {
      return (
        <HStack
          name="references"
          style={{
            background: "#F3F5F8",
            minHeight: "80vh",
            justifyContent: "center",
            alignItems: "center",
            padding: "32px",
          }}
        >
          <VStack
            style={{
              alignSelf: "center",
              maxHeight: "100%",
              width: "40vw",
              height: "100%",
              justifyContent: "center",
              maxWidth: "350px",
            }}
          >
            <CustomerImage />
          </VStack>
          <div style={{ minWidth: "8px", maxWidth: "64px", flexGrow: "1" }} />
          <VStack
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              width: "50vw",
              maxWidth: "400px",
              marginLeft: "8px",
            }}
          >
            <HStack>
              <VStack style={{ padding: "1rem" }}>
                <HStack>
                  <RefQuote>
                    SmileBell has been a true asset in our return to work plans
                    with COVID-19. The contactless system makes it easy for all
                    visitors to check-in with a simple smile at the screen, and
                    the immediate messaging alert to our HR team makes working
                    without a receptionist no problem.
                  </RefQuote>
                </HStack>

                <RefQuoteName style={{ marginTop: "1rem" }}>
                  Rachel Valentine
                </RefQuoteName>
                <RefQuoteSign>Head of People Operations</RefQuoteSign>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      )
    } else {
      return (
        <VStack
          name="references"
          style={{
            background: "#F3F5F8",
            minHeight: "50vh",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <VStack
            style={{
              alignSelf: "center",
              maxHeight: "100%",
              width: "40vw",
              height: "100%",
              justifyContent: "center",
              maxWidth: "450px",
            }}
          >
            <CustomerImage />
          </VStack>
          <VStack
            style={{
              alignSelf: "stretch",
              justifyContent: "center",
              margin: "16px",
            }}
          >
            <RefQuote style={{ padding: "1rem", textAlign: "center" }}>
              SmileBell has been a true asset in our return to work plans with
              COVID-19. The contactless system makes it easy for all visitors to
              check-in with a simple smile at the screen, and the immediate
              messaging alert to our HR team makes working without a
              receptionist no problem.
            </RefQuote>
            <VStack style={{ textAlign: "end", paddingRight: "2rem" }}>
              <RefQuoteName>Rachel Valentine</RefQuoteName>
              <RefQuoteSign>Head of People Operations</RefQuoteSign>
            </VStack>
          </VStack>{" "}
        </VStack>
      )
    }
  }

  return (
    <>
      <NavBar>
        <HStack>
          <SmileBellLogo>SmileBell®</SmileBellLogo>
          {/* <By style={{ padding: "8px", opacity: 0.5 }}>by</By>
          <div>
            <a target="_blank" href="https://vincit.com">
              <img
                src={vincitLogo}
                alt="Vincit"
                style={{ padding: "4px 0 0 0", opacity: 0.5 }}
              />
            </a>
          </div> */}
        </HStack>
        <HStack>
          {width > 400 ? (
            <>
              <Link to="/#references" style={{ textDecoration: "none" }}>
                <NavLink>References</NavLink>
              </Link>

              <Link to="/#integrations" style={{ textDecoration: "none" }}>
                <NavLink>Integrations</NavLink>
              </Link>
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <NavLink>Get In Touch</NavLink>
              </Link>
            </>
          ) : null}
        </HStack>
      </NavBar>

      <Layout>
        <SEO title="Smilebell by Vincit" />
        <VStack>
          <VStack
            style={{
              alignSelf: "center",
              minHeight: "100vh",
              padding: "64px 32px 0px 32px",
              justifyItems: "space-around",
              justifyContent: "space-around",
            }}
          >
            <div style={{ padding: "64px 32px 32px 32px" }}>
              <Heading1>Checking in has never been easier.</Heading1>
              <Subheader style={{ marginTop: "1rem" }}>
                Smilebell. A touchless check-in system for offices.
              </Subheader>
              <VStack style={{ margin: "1rem", justifyContent: "center" }}>
                <div style={{ alignSelf: "center" }}>
                  <Button onClick={toContactPage} text="Get in touch" />
                </div>
              </VStack>
            </div>
            <HStack
              style={{
                alignItems: "center",
                marginBottom: "32px",
              }}
            >
              <VStack
                style={{
                  alignItems: "flex-start",
                  padding: "0px 2rem",
                }}
              >
                <ProcessStep
                  currentPhase={currentPhase}
                  phase={1}
                  title="enter the lobby"
                  body="Visitors entering your office are greeted with SmileBell."
                />
                <ProcessStep
                  currentPhase={currentPhase}
                  phase={2}
                  title="smile at the SmileBell"
                  body="All you need to do is smile for a couple of seconds. Not so
              hard, right?"
                />
                <ProcessStep
                  currentPhase={currentPhase}
                  phase={3}
                  title="wait to be greeted"
                  body="Sit tight and SmileBell® will notify your defined person with a
              message and photo."
                />
              </VStack>
              <div style={{ width: "48vw", maxWidth: "400px" }}>
                {renderAnimationImage(currentPhase)}
              </div>
            </HStack>
          </VStack>
        </VStack>

        {renderRef()}
        {renderSlack()}

        <VStack
          style={{
            background: "#F3F5F8",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "48px 32px",
          }}
        >
          <VStack
            style={{
              alignItems: "center",
              justifyContent: "center",
              padding: "16px 16px",
              textAlign: "center",
            }}
          >
            <FeatureTitle style={{ paddingBottom: "0.5rem" }}>
              Make visiting your office easier today!
            </FeatureTitle>
          </VStack>
          <div style={{ alignSelf: "center", margin: "1rem" }}>
            <Button onClick={toContactPage} text="Get in touch with us" />
          </div>
        </VStack>
      </Layout>
    </>
  )
}

export default IndexPage
